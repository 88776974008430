// 后端接口都是下划线链接，这里忽略驼峰校验
/* eslint-disable camelcase */
import {
  getJson,
  deleteJson,
  postJSON,
  postFormData,
} from 'components/api/request';
import { ApiRequest, ProductsReqBase } from 'components/api/types';
import { ATTRIBUTE_STATUS, ATTRIBUTE_TYPE } from 'components/constants/app';

interface PostApiV1ProductsAttributeReq extends ProductsReqBase {
  /** 类型  ad_status: 广告开关 */
  type: string;
  /** 状态  on: 开; off： 关。 */
  data: string;
}

interface PostApiV1ProductsAttributeResp {
  status: number;
  data: any;
}

export interface PostApiV1ProductsAttributeRespData {
  user_contact: string;
  team_title: string;
  team_name: string;
  logo: string;
  display_mode: number;
  ad_status: string;
}

interface GetApiV1ProductsAttributeReq extends ProductsReqBase {
  /** 类型  ad_status: 广告开关 */
  type: ATTRIBUTE_TYPE;
}
interface GetApiV1ProductsAttributeResp {
  status: number;
  data: any;
}

/**
 * 产品-属性配置-更新
 */
export const postApiV1ProductsAttribute: ApiRequest<
  PostApiV1ProductsAttributeReq,
  PostApiV1ProductsAttributeResp
> = ({ productId, type, data }) => postJSON({
  url: `/api/v1/${productId}/products/attribute`,
  data: {
    type,
    data,
  },
});

/**
 * 产品-属性配置-获取
 */
export const getApiV1ProductsAttribute: ApiRequest<
  GetApiV1ProductsAttributeReq,
  GetApiV1ProductsAttributeResp
> = ({ productId, type }) => getJson({
  url: `/api/v1/${productId}/products/attribute`,
  data: { type },
});

/**
 * 产品-验证码开关-更新
 **/
export const postApiV1ProductsSliderCaptchaEnabled: ApiRequest<
  {
    productId: string;
    enabled: 1 | 0;
  },
  { status: number; data: any }
> = ({ productId, enabled }) => postJSON({
  url: `/api/v1/${productId}/products/is_slider_captcha_enabled`,
  data: {
    is_slider_captcha_enabled: enabled,
  },
});
