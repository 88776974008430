/* 需要特殊判定的 webview 运行环境 */
export const ALL_ENV = {
  /* 企业微信 */
  wxwork: 'wxwork',
  /* 微信 */
  wechat: 'wx',
  /* 微信小程序web-view https://developers.weixin.qq.com/miniprogram/dev/component/web-view.html */
  miniprogramWeb: 'miniprogramWeb',
  /* 微信小程序 */
  miniprogram: 'wx_mini',
  /* 微信小程序插件 */
  miniprogramPlugin: 'miniprogramPlugin',
  /* qq */
  qq: 'qq',
  /* 其他 */
  normal: 'else',
};
const ua = navigator.userAgent.toLowerCase();

/**
 * 判定当前 webview 运行环境
 * @returns {string}
 */
export function getEnv() {
  /* 小程序判断在编译时 */
  if (isWxMiniProgram()) {
    return ALL_ENV.miniprogram;
  }
  /* 小程序web-view的特征包含微信和企业微信，需要先判定小程序web-view */
  if (isWxMiniProgramWebView()) {
    return ALL_ENV.miniprogramWeb;
  }

  /* 企业微信特征包含微信，需要先判定企业微信 */
  if (isWxWorkMobile()) {
    return ALL_ENV.wxwork;
  }

  /* qq */
  if (isMQQ()) {
    return ALL_ENV.qq;
  }

  /* 微信 */
  if (isWeChat()) {
    return ALL_ENV.wechat;
  }

  /* 普通 web */
  return ALL_ENV.normal;
}



/**
 * 判定是否手机QQ
 * @returns {boolean}
 */
export function isMQQ() {
  return Boolean(ua.match(/qq\/([\d]+)/));
}

/**
 * 判定是否微信
 * @returns {boolean}
 */
export function isWeChat() {
  return Boolean(ua.match(/micromessenger\/([\d]+)/)) && !isWxWorkMobile() && !isWxWorkPC();
}

/**
 * 企业微信ua均包含wxwork 但pc没有mobile检测指针
 * @returns {boolean}
 */
export function isWxWorkMobile() {
  return Boolean(ua.match(/wxwork\/([\d]+)/)) && (ua.includes('mobile'));
}

/**
 * 企业微信ua均包含wxwork 但pc没有mobile检测指针
 * @returns {boolean}
 */
export function isWxWorkPC() {
  return Boolean(ua.match(/wxwork\/([\d]+)/)) && !ua.includes('mobile');
}

/**
 * 判定是否微信小程序
 * @returns {boolean}
 */
export function isWxMiniProgram() {
  try {
    return process.env && process.env.TARO_ENV === 'weapp';
  } catch (e) {
    //
  }

  return false;
}

/**
 * 判定是否微信小程序
 * @link https://developers.weixin.qq.com/miniprogram/dev/component/web-view.html
 * @returns {boolean}
 */
export function isWxMiniProgramWebView() {
  // 微信 7.0.0 支持通过 UA 判定
  if (ua.match(/miniprogram/)) {
    return true;
  }

  // 微信 7.0.0 以下版本通过全局变量判定
  return Boolean(window.__wxjs_environment === 'miniprogram');
}


/**
 * 判定是否 iOS 设备
 */
export function isIOS() {
  const isIphone = ua.indexOf('iphone') != -1;
  const isIpod = ua.indexOf('ipod') != -1;
  const isIpad = ua.indexOf('ipad') != -1;
  return isIphone || isIpod || isIpad;
}

/**
 * 判定是否为Android
 * @returns {boolean}
 */
export function isAndroid() {
  return ua.indexOf('android') > -1 || ua.indexOf('adr') > -1;
}

/** 是否是苹果设备 */
export const isAppleDevice = /AppleWebKit/i.test(ua);

/** 老系统ipad, ua中有ipad关键字，直接判断就好了 */
export const isOldIpad = isAppleDevice && /ipad/.test(ua);
/**
 * 新系统（15）ua和mac是一致的，无法从ua上区分开ipad和mac， 需要组合判断
 * 判断是否ipad，Window.orientation 已废弃，但目前 ipad/iphone 上还在使用
 * iOS 15.4 ipad/ipad air/ipad pro 的ua 已经变成
 * Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.4 Safari/605.1.15
 */
export const isNewIpad = isAppleDevice && !(/mobile\/\w+/i.test(ua)) && typeof window.orientation === 'number';

export const isIpad = isOldIpad || isNewIpad;


/** 是否是移动端设备 */
export const isMobile = (isAppleDevice && /mobile/.test(ua))
|| /android|iphone|webos|blackberry|opera mini|opera mobi|iemobile/i.test(ua)
|| process.env.TARO_ENV === 'weapp';


export const isPc = !isIpad && !isMobile;
